export const formatPrice = (price: number, currency = 'EUR') => {
  // info: maybe the format `de` should be dynamic based on the language
  const formatter = new Intl.NumberFormat('de', {
    style: 'currency',
    currency,
    minimumFractionDigits: price > 0 && price >= 1000 ? 3 : 0,
    maximumFractionDigits: price > 0 && price >= 1000 ? 3 : 0
  })

  const formattedPrice = price >= 1000 ? price / 1000 : price

  return formatter.format(formattedPrice).replace(/,/g, '.')
}
